import { CSSProperties } from 'react';
import { ThemeColor } from 'styleguide/src/themes/theme';
import { DefaultTheme } from 'styled-components';
//aaa
export function getColorFromTheme(
  theme: DefaultTheme,
  color: CSSProperties['background'] | CSSProperties['backgroundColor'],
) {
  return theme.colors[color as ThemeColor] || color;
}
