import NotFound from 'styleguide/src/components/website/NotFound';
import Layout from 'components/Layout';
import { LayoutPage, getStaticLayoutProps } from 'utils/layoutData';

export const getStaticProps = getStaticLayoutProps;

const Page404: LayoutPage = ({ ...layoutProps }) => {
  return (
    <Layout {...layoutProps}>
      <NotFound />
    </Layout>
  );
};

export default Page404;
