import {
  BlogHeader as BaseBlogHeader,
  BlogHeaderProps,
} from 'styleguide/src/components/Blog';
import { HTMLAttributes } from 'react';

type Props = BlogHeaderProps & {
  shareUrl: string;
  attributes?: Omit<HTMLAttributes<HTMLElement>, 'color'>;
  className?: string;
};

function BlogHeader(props: Props) {
  const { title, subtitle, date, shareUrl, className, attributes } = props;
  if (!title) return null;

  return (
    <BaseBlogHeader
      {...attributes}
      className={`${className || ''} ${attributes?.className || ''}`}
      title={title}
      subtitle={subtitle}
      date={date}
      postUrl={shareUrl}
    />
  );
}

export default BlogHeader;
