import { Builder } from '@builder.io/react';
import { PageModel } from 'types/pageModels';
import BlogHeader from './BlogHeader';
import HeroImage from './HeroImage';
import BodyText from './BodyText';
import SubSectionHeaderText from './SubSectionHeaderText';
import SectionHeaderText from './SectionHeaderText';
import BlogContainer from './BlogContainer';
import CallToAction from './CallToAction';
import MediaContainer from './MediaContainer';
import { MediaContainerSize } from 'styleguide/src/components/Blog';
import BlogImage from './BlogImage';
import BlogVideo from './BlogVideo';
import { InputOnChange } from '../types';

Builder.registerComponent(BlogHeader, {
  models: [PageModel.BLOG],
  name: 'Blog Header',
  noWrap: true,
  inputs: [
    {
      name: 'shareUrl',
      type: 'url',
      required: true,
    },
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Blog Title',
      required: true,
    },
    {
      name: 'subtitle',
      type: 'string',
      defaultValue: 'Blog Sub Title',
      required: false,
    },
    {
      name: 'date',
      type: 'date',
    },
  ],
  override: true,
});

Builder.registerComponent(HeroImage, {
  models: [PageModel.BLOG],
  name: 'Hero Image',
  noWrap: true,
  inputs: [
    {
      name: 'image',
      type: 'object',
      defaultValue: {
        alt: 'Hero Image',
      },
      subFields: [
        {
          name: 'src',
          type: 'file',
          required: true,
        },
        {
          name: 'alt',
          type: 'string',
          required: true,
        },
      ],
    },
    {
      name: 'description',
      type: 'string',
      required: false,
    },
  ],
  override: true,
});

Builder.registerComponent(BodyText, {
  models: [PageModel.BLOG],
  name: 'Body Text',
  noWrap: true,
  inputs: [
    {
      name: 'text',
      type: 'richText',
      defaultValue: 'Text goes here...',
      required: true,
    },
  ],
  override: true,
});

Builder.registerComponent(SectionHeaderText, {
  models: [PageModel.BLOG],
  name: 'Section Header And Text',
  noWrap: true,
  inputs: [
    {
      name: 'headerText',
      type: 'longText',
      defaultValue: 'Header text goes here...',
      required: true,
    },
    {
      name: 'text',
      type: 'richText',
    },
  ],
  override: true,
});

Builder.registerComponent(SubSectionHeaderText, {
  models: [PageModel.BLOG],
  name: 'Sub Section Header And Text',
  noWrap: true,
  inputs: [
    {
      name: 'headerText',
      type: 'longText',
      defaultValue: 'Header text goes here...',
      required: true,
    },
    {
      name: 'text',
      type: 'richText',
    },
  ],
  override: true,
});

Builder.registerComponent(CallToAction, {
  models: [PageModel.BLOG],
  name: 'Call To Action',
  noWrap: true,
  inputs: [
    {
      name: 'headerText',
      type: 'longText',
      defaultValue: 'Header text goes here...',
      required: true,
    },
    {
      name: 'text',
      type: 'richText',
    },
    {
      name: 'button',
      type: 'object',
      defaultValue: {
        text: 'Button text goes here...',
      },
      subFields: [
        {
          name: 'text',
          type: 'string',
          required: true,
        },
        {
          name: 'url',
          type: 'url',
          required: true,
        },
      ],
    },
  ],
  override: true,
});

Builder.registerComponent(BlogImage, {
  models: [PageModel.BLOG],
  name: 'Blog Image',
  noWrap: true,
  inputs: [
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png'],
      required: true,
    },
    {
      name: 'alt',
      type: 'string',
      required: true,
      defaultValue: 'Blog Image Alt Text...',
    },
    {
      name: 'size',
      type: 'string',
      enum: [
        MediaContainerSize.small,
        MediaContainerSize.medium,
        MediaContainerSize.large,
      ],
      defaultValue: MediaContainerSize.large,
    },
    {
      name: 'outline',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'imageFit',
      type: 'string',
      enum: ['cover', 'contain', 'fill', 'none'],
      defaultValue: 'cover',
    },
  ],
});

Builder.registerComponent(BlogVideo, {
  models: [PageModel.BLOG],
  name: 'Blog Video',
  noWrap: true,
  inputs: [
    {
      name: 'video',
      type: 'file',
      allowedFileTypes: ['mp4'],
      required: true,
      onChange: async (options: InputOnChange<string, string>) => {
        const videoUrl = options.get('video');
        try {
          const response = await fetch(videoUrl, { method: 'HEAD' });
          const contentLength = response.headers.get('Content-Length');
          const maxSizeInMB = 1; // Limit size to 1MB

          if (
            contentLength &&
            parseInt(contentLength) > maxSizeInMB * 1024 * 1024
          ) {
            alert(`File size should not exceed ${maxSizeInMB}MB`);
            options.set('video', undefined);
          }
        } catch (error) {
          alert('Failed to verify video file size.');
          options.set('video', undefined);
        }
      },
    },
    {
      name: 'controls',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'size',
      type: 'string',
      enum: [
        MediaContainerSize.small,
        MediaContainerSize.medium,
        MediaContainerSize.large,
      ],
      defaultValue: MediaContainerSize.large,
    },
    {
      name: 'outline',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'videoFit',
      type: 'string',
      enum: ['cover', 'contain', 'fill', 'none'],
      defaultValue: 'cover',
    },
  ],
});

Builder.registerComponent(MediaContainer, {
  models: [PageModel.BLOG],
  name: 'Media Container',
  noWrap: true,
  inputs: [
    {
      name: 'size',
      type: 'string',
      enum: [
        MediaContainerSize.small,
        MediaContainerSize.medium,
        MediaContainerSize.large,
      ],
      defaultValue: MediaContainerSize.large,
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: { text: 'Add your sub-components here' },
      },
    },
  ],
});

Builder.registerComponent(BlogContainer, {
  models: [PageModel.BLOG],
  name: 'Blog Container',
  noWrap: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: { text: 'Add your sub-components here' },
      },
    },
  ],
});
