import styled from 'styled-components';
import Typography from '../../Typography';

export const Wrapper = styled.div`
  width: 100%;
  max-width: min(
    calc(100vw - 48px),
    ${({ theme }) => theme.variables.maxWidth}px
  );
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 5rem 0;
  box-sizing: border-box;
  flex-direction: column;
`;

export const Heading = styled(Typography)``;

export const BackButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  @media ${({ theme }) => theme.breakpoints.large} {
    justify-content: flex-start;
  }
`;
