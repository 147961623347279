import { Builder } from '@builder.io/react';
import theme from 'styleguide/src/themes/theme';
import { buttonVariants } from 'styleguide/src/components/Button';
import { pageModel } from 'types/pageModels';
import Button from './Button';
import Text from './Text';
import SvgIcon from './SvgIcon';

const BaseComponentInputProps = [
  {
    name: 'variant',
    type: 'string',
    defaultValue: 'inherit',
    enum: ['inherit', 'primary', 'secondary'],
  },
];

const BaseTextComponentProps = [
  {
    name: 'color',
    type: 'string',
    enum: Object.keys(theme.colors),
    defaultValue: 'black',
  },
  {
    name: 'align',
    type: 'string',
    enum: ['left', 'right', 'center', 'justify'],
    defaultValue: 'left',
  },
  { name: 'fontSize', type: 'number' },
  {
    name: 'fontWeight',
    type: 'string',
    enum: ['300', '400', '500', '600', '700', '800'],
  },
  { name: 'lineHeight', type: 'string' },
  { name: 'letterSpacing', type: 'string' },
];

Builder.registerComponent(Button, {
  name: 'Button',
  noWrap: true,
  inputs: [
    { name: 'buttonText', type: 'string', defaultValue: 'Button text' },
    { name: 'buttonUrl', type: 'string', required: false },
    {
      name: 'variant',
      type: 'string',
      enum: Array.from(buttonVariants),
      defaultValue: 'primary',
    },
    {
      name: 'buttonUrlScrollToTop',
      type: 'boolean',
      defaultValue: true,
      helperText:
        'Controls whether to scroll to top of the page when button has a url to another page.',
    },
  ],
  override: true,
});

Builder.registerComponent(SvgIcon, {
  name: 'SvgIcon',
  noWrap: true,
  inputs: [
    { name: 'icon', type: 'code' },
    { name: 'size', type: 'string', defaultValue: '64px' },
    { name: 'label', type: 'string' },
    {
      name: 'unthemed',
      type: 'boolean',
      helperText:
        'By default we will modify the SVG to match the theme. Turn this off to not modify the theme.',
      defaultValue: false,
    },
    ...BaseComponentInputProps,
  ],
});

Builder.registerComponent(Text, {
  models: [pageModel],
  name: 'Text',
  noWrap: true,
  inputs: [
    { name: 'text', type: 'longText', defaultValue: 'Text goes here...' },
    {
      name: 'variant',
      type: 'string',
      enum: [
        'bodyLarge',
        'bodyStandard',
        'bodySmall',
        'ctaStandard',
        'ctaSmall',
        'eyebrowSmall',
        'eyebrowLarge',
      ],
      defaultValue: 'bodyLarge',
    },
    {
      name: 'tagName',
      type: 'string',
      enum: ['p', 'span', 'div', 'li'],
      defaultValue: 'p',
    },
    ...BaseTextComponentProps,
  ],
  override: true,
});

Builder.registerComponent(Text, {
  models: [pageModel],
  name: 'Heading',
  noWrap: true,
  inputs: [
    {
      name: 'text',
      type: 'longText',
      defaultValue: 'Some Heading Typography...',
    },
    {
      name: 'variant',
      type: 'string',
      enum: [
        'heading1',
        'heading2',
        'heading3',
        'heading4',
        'heading5',
        'heading6',
      ],
      defaultValue: 'heading1',
    },
    {
      name: 'tagName',
      type: 'string',
      enum: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
      defaultValue: 'p',
    },
    ...BaseTextComponentProps,
  ],
});
