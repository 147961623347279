import { HTMLAttributes } from 'react';
import styled, { StyleSheetManager, ThemeProvider } from 'styled-components';
import { SectionVariant } from '../types';
import { getBestMatchingColor } from '../../../utils/styleUtils';

const StyledWrapper = styled.section`
  width: 100%;
  background: ${getBestMatchingColor('background')};
`;

interface Props {
  className?: string;
  children: React.ReactNode;
  variant?: SectionVariant;
  tagName?: 'header' | 'section';
  attributes?: HTMLAttributes<HTMLElement>;
}

function SectionWrapper({
  attributes,
  variant,
  className,
  children,
  tagName,
}: Props) {
  return (
    <StyleSheetManager shouldForwardProp={() => true}>
      {/* @ts-expect-error theme can be extended */}
      <ThemeProvider theme={{ variant }}>
        <StyledWrapper
          as={tagName}
          {...attributes}
          className={`${className} ${attributes?.className || ''}`}
        >
          {children}
        </StyledWrapper>
      </ThemeProvider>
    </StyleSheetManager>
  );
}

export default SectionWrapper;
