import {
  HeroImage as BaseHeroImage,
  HeroImageProps,
} from 'styleguide/src/components/Blog';
import { HTMLAttributes } from 'react';

type Props = HeroImageProps & {
  attributes?: Omit<HTMLAttributes<HTMLElement>, 'color'>;
  className?: string;
};

function HeroImage(props: Props) {
  const { image, description, attributes } = props;

  if (!image) return null;

  return (
    <BaseHeroImage {...attributes} image={image} description={description} />
  );
}

export default HeroImage;
