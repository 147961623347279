import {
  useMemo,
  useCallback,
  createElement,
  ReactHTML,
  ComponentType,
  ComponentProps,
} from 'react';
import parse, {
  HTMLReactParserOptions,
  domToReact,
  Element,
  DOMNode,
} from 'html-react-parser';
import Link from 'next/link';
import { mergeDeep } from '../utils/mergeObject';
import styled from 'styled-components';
import { sanitize, Config } from 'isomorphic-dompurify';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.machineGreen600};
`;

const defaultSanitizeConfig: Config = {
  USE_PROFILES: {
    html: true,
    mathMl: false,
    svg: true,
    svgFilters: true,
  },
  SANITIZE_NAMED_PROPS: true,
};

export type ParseComponents = {
  [key in keyof ReactHTML]?: ComponentType<ComponentProps<key>>;
};

interface HookProps {
  parseComponents?: ParseComponents;
}

const defaultParseComponents: ParseComponents = {
  a: ({ href, children, ref, ...rest }) => (
    <StyledLink
      href={href || ''}
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...rest}
    >
      {children}
    </StyledLink>
  ),
};

const useParseHTML = ({ parseComponents }: HookProps = {}) => {
  const parseOptions: HTMLReactParserOptions = useMemo(() => {
    const mergedParseComponents = mergeDeep(
      {},
      defaultParseComponents,
      parseComponents || {},
    );
    return {
      replace: (domNode) => {
        const typedDomNode = domNode as Element;
        if (
          typedDomNode &&
          mergedParseComponents[typedDomNode.name as keyof ReactHTML]
        ) {
          const name = typedDomNode.name;
          const attribs = typedDomNode.attribs;
          const children =
            typedDomNode.children?.length > 0
              ? domToReact(typedDomNode.children as DOMNode[], parseOptions)
              : null;
          return createElement(
            // eslint-disable-next-line
            // @ts-ignore TODO: strongly type this
            mergedParseComponents[name as keyof ReactHTML],
            { ...attribs },
            children,
          );
        }
      },
    };
  }, [parseComponents]);

  const sanitizeContent = useCallback((content: string, config?: Config) => {
    if (!content) return content;
    return sanitize(content, {
      ...defaultSanitizeConfig,
      ...(config || {}),
      RETURN_DOM: false,
      RETURN_DOM_FRAGMENT: false,
      RETURN_TRUSTED_TYPE: false,
    });
  }, []);

  const parseContent = useCallback(
    (html?: string, config?: Config) => {
      if (typeof html !== 'string') return undefined;
      const cleanString = sanitizeContent(html, config) as string;
      return parse(cleanString, parseOptions);
    },
    [parseOptions, sanitizeContent],
  );

  return {
    parseContent,
    sanitizeContent,
  };
};

export default useParseHTML;
