import { HTMLAttributes } from 'react';
import { withChildren } from '@builder.io/react';
import Container from 'styleguide/src/components/Blog/Container/Container';

type Props = {
  attributes?: Omit<HTMLAttributes<HTMLElement>, 'color'>;
  children: React.ReactNode;
};

function BlogContainer({ children, attributes }: Props) {
  return <Container {...attributes}>{children}</Container>;
}

export default withChildren(BlogContainer);
