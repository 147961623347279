import BaseButton, { ButtonProps } from 'styleguide/src/components/Button';
import { HTMLAttributes } from 'react';

type Props = ButtonProps & {
  buttonText?: string;
  buttonUrl?: string;
  buttonUrlScrollToTop?: boolean;
  attributes?: Omit<HTMLAttributes<HTMLElement>, 'color'>;
};

function Button({
  buttonText,
  buttonUrl,
  buttonUrlScrollToTop,
  variant,
  className,
  isLoading,
  attributes,
  type,
}: Props) {
  if (!buttonText) return null;
  return (
    <BaseButton
      {...(buttonUrl ? { href: buttonUrl, scroll: buttonUrlScrollToTop } : {})}
      variant={variant}
      isLoading={isLoading}
      type={type}
      {...attributes}
      className={`${className || ''} ${attributes?.className || ''}`}
    >
      {buttonText}
    </BaseButton>
  );
}

export default Button;
