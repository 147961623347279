import styled from 'styled-components';
import { CSSProperties } from 'react';

export const sideBySideVariants = [
  'left-top',
  'right-top',
  'left-bottom',
  'right-bottom',
] as const;

export type SideBySideVariant = (typeof sideBySideVariants)[number];

export const StyledSideBySide = styled.div<{
  $background: CSSProperties['background'];
}>`
  background: ${({ $background }) => $background};
  padding: 30px 20px;
  @media ${({ theme }) => theme.breakpoints.large} {
    padding: 50px;
  }
`;

function getOrderForChild({
  $variant,
  child = 'first',
  mobile = false,
}: {
  $variant: SideBySideVariant;
  child?: 'first' | 'second';
  mobile?: boolean;
}) {
  if (mobile) {
    switch ($variant) {
      case 'left-top':
      case 'right-top':
        return child === 'first' ? 1 : 2;
      case 'left-bottom':
      case 'right-bottom':
        return child === 'first' ? 2 : 1;
    }
  } else {
    switch ($variant) {
      case 'left-top':
      case 'left-bottom':
        return child === 'first' ? 1 : 2;
      case 'right-top':
      case 'right-bottom':
        return child === 'first' ? 2 : 1;
    }
  }
}

export const SideBySideContent = styled.div<{
  $maxHeight?: number;
  $backgroundColor: CSSProperties['backgroundColor'];
  $variant: SideBySideVariant;
}>`
  > :first-child {
    flex: 1;
    aspect-ratio: 1;
    ${({ $maxHeight }) => ($maxHeight ? `max-height: ${$maxHeight}px` : '')};
    overflow: hidden;
    order: ${({ $variant }) => getOrderForChild({ $variant, mobile: true })};
    border-radius: 20px;
    @media ${({ theme }) => theme.breakpoints.large} {
      border-radius: 40px;
      order: ${({ $variant }) => getOrderForChild({ $variant })};
    }
  }
  > :nth-child(2) {
    flex: 1;
    order: ${({ $variant }) =>
      getOrderForChild({ $variant, child: 'second', mobile: true })};
    @media ${({ theme }) => theme.breakpoints.large} {
      order: ${({ $variant }) =>
        getOrderForChild({ $variant, child: 'second' })};
    }
  }
  max-width: 1340px;
  width: 100%;
  margin: 0 auto;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 20px;
  @media ${({ theme }) => theme.breakpoints.large} {
    border-radius: 40px;
    flex-direction: row;
    align-items: center;
    ${({ $maxHeight }) => ($maxHeight ? `max-height: ${$maxHeight}px` : '')};
  }
`;
