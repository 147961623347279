import type { AnchorHTMLAttributes, PropsWithChildren } from 'react';

import { ButtonLinkStyled, LinkStyled, NextLinkStyled } from './Link.styles';

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href?: string;
  external?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Link = ({
  href,
  external,
  disabled,
  children,
  className,
  style,
  onClick,
}: PropsWithChildren<LinkProps>) => {
  const props = {
    disabled,
    className,
    style,
  };

  if (onClick && !href) {
    return (
      <ButtonLinkStyled type="button" onClick={onClick} {...props}>
        {children}
      </ButtonLinkStyled>
    );
  }
  if (!href) {
    return null;
  }
  if (external) {
    return (
      <LinkStyled href={href} onClick={onClick} target="_blank" {...props}>
        {children}
      </LinkStyled>
    );
  }
  return (
    <NextLinkStyled href={href} onClick={onClick} {...props}>
      {children}
    </NextLinkStyled>
  );
};

Link.defaultProps = {
  external: false,
  disabled: false,
  className: '',
} satisfies Partial<LinkProps>;

export default Link;
