import { styled } from 'styled-components';
import { HTMLAttributes } from 'react';
import Container from 'styleguide/src/components/Blog/Container/Container';
import useParseHTML from 'styleguide/src/hooks/useParseHTML';
import Typography from 'styleguide/src/components/Typography';

const StyledText = styled(Typography)`
  margin: 24px 0;
`;

type Props = {
  attributes?: Omit<HTMLAttributes<HTMLElement>, 'color'>;
  className?: string;
  text: string;
};

function BodyText(props: Props) {
  const { text, className, attributes } = props;
  const { parseContent } = useParseHTML();

  if (!text) return null;

  return (
    <Container
      {...attributes}
      className={`${className || ''} ${attributes?.className || ''}`}
    >
      <StyledText tagName="div" variant="bodyStandard" color="black">
        {parseContent(text)}
      </StyledText>
    </Container>
  );
}

export default BodyText;
