type Target = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};
type Source = Target;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObject = (item: any) => {
  return item && typeof item === 'object';
};

export const mergeDeep = (target: Target, ...sources: Source[]): Target => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (!isObject(source[key])) {
        Object.assign(target, {
          [key]: source[key],
        });
      } else {
        if (!target[key])
          Object.assign(target, {
            [key]: {},
          });
        mergeDeep(target[key], source[key]);
      }
    }
  }

  return mergeDeep(target, ...sources);
};
