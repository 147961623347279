import React, { CSSProperties, PropsWithChildren } from 'react';
import {
  SideBySideContent,
  SideBySideVariant,
  StyledSideBySide,
} from './SideBySide.styles';
import { useTheme } from 'styled-components';

export type SideBySideProps = PropsWithChildren<{
  $containerBackground?: CSSProperties['background'];
  backgroundColor?: CSSProperties['backgroundColor'];
  variant?: SideBySideVariant;
  maxHeight?: number;
}>;

export default function SideBySide(props: SideBySideProps) {
  const {
    $containerBackground,
    backgroundColor,
    variant = 'left-top',
    maxHeight,
    children,
  } = props;

  const theme = useTheme();

  return (
    <StyledSideBySide $background={$containerBackground || theme.colors.white}>
      <SideBySideContent
        $backgroundColor={backgroundColor || theme.colors.gray50}
        $variant={variant}
        $maxHeight={maxHeight}
      >
        {children}
      </SideBySideContent>
    </StyledSideBySide>
  );
}
