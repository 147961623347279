import SideBySideComponent, {
  SideBySideProps,
} from 'styleguide/src/components/website/SideBySide';
import { withChildren } from '@builder.io/react';
import { getColorFromTheme } from '../../../utils/helper';
import { useTheme } from 'styled-components';

function SideBySide(props: SideBySideProps) {
  const { $containerBackground, backgroundColor, ...sideBySideProps } = props;
  const theme = useTheme();

  return (
    <SideBySideComponent
      $containerBackground={
        $containerBackground && getColorFromTheme(theme, $containerBackground)
      }
      backgroundColor={
        backgroundColor && getColorFromTheme(theme, backgroundColor)
      }
      {...sideBySideProps}
    />
  );
}

export default withChildren(SideBySide);
