export const env = process.env.NODE_ENV;
export const firebaseId = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
// try to keep this consistent with the backend config files
// Is running in GCP
export const isDeployed = env === 'production';
// Is running on dev machine
export const isDevelopment = Boolean(!isDeployed);
// staging is the dev.wreno.io env
// keep in mind that we have support for custom domains so cant rely on the domain
export const isStaging = Boolean(isDeployed && firebaseId?.includes('dev'));
// Is the true production environment
export const isProduction = Boolean(isDeployed && !isStaging);
