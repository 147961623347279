import { styled } from 'styled-components';
import { HTMLAttributes } from 'react';
import Container from 'styleguide/src/components/Blog/Container/Container';
import Typography from 'styleguide/src/components/Typography';
import useParseHTML from 'styleguide/src/hooks/useParseHTML';

const StyledText = styled(Typography)`
  margin: 0 0 24px 0;
`;

const StyledHeader = styled(Typography)`
  margin: 48px 0 16px;
`;

type Props = {
  attributes?: Omit<HTMLAttributes<HTMLElement>, 'color'>;
  className?: string;
  headerText: string;
  text?: string;
};

function SectionHeaderText(props: Props) {
  const { headerText, text, className, attributes } = props;
  const { parseContent } = useParseHTML();

  if (!headerText) {
    return null;
  }

  return (
    <Container
      {...attributes}
      className={`${className || ''} ${attributes?.className || ''}`}
    >
      <StyledHeader variant="heading4" tagName="h3">
        {headerText}
      </StyledHeader>
      {text && (
        <StyledText tagName="div" variant="bodyStandard" color="black">
          {parseContent(text)}
        </StyledText>
      )}
    </Container>
  );
}

export default SectionHeaderText;
