import { builder as baseBuilder } from '@builder.io/react';
import { isProduction } from 'common/constants/environment';

export const cacheTime = isProduction ? 60 * 30 : 5; // 30 minutes for production, 5 seconds for development

export const cacheArgs = {
  cachebust: true,
  cache: false,
  // ensure cache is not used amd we leverage ISR for cache instead
  // updates less than 1 minute apart will not be reflected
  staleCacheSeconds: 60,
};

export const initializeBuilder = () => {
  const builder = baseBuilder.init(
    process.env.NEXT_PUBLIC_BUILDER_API_KEY || '',
  );
  return builder;
};

const builder = initializeBuilder();
export default builder;
