import styled from 'styled-components';
import { BaseComponentProps, ComponentVariant } from '../types';

const Icon = styled.div.withConfig({
  // builder.io passes us attributes for this element, but styled-components was stripping
  shouldForwardProp: () => true,
})<{ $size?: string; $variant?: ComponentVariant; $unthemed?: boolean }>`
  color: ${({ theme }) => `${theme.colors.machineGreen600}`};
  svg {
    display: inline-block;
    height: ${({ $size }) => $size || '1rem'};
    width: ${({ $size }) => $size || '1rem'};
  }
  svg path {
    ${({ $unthemed }) => !$unthemed && 'fill: currentColor;'}
  }
`;

interface Props extends BaseComponentProps {
  icon: string;
  size?: string;
  label?: string;
  className?: string;
  // don't muck with svg to match theme
  unthemed?: boolean;
}

function SvgIcon({
  icon,
  unthemed,
  attributes,
  className,
  variant,
  label,
  size,
}: Props) {
  if (!icon) {
    return null;
  }
  return (
    <Icon
      $size={size}
      $variant={variant}
      $unthemed={unthemed}
      {...attributes}
      className={`${className || ''} ${attributes?.className || ''}`}
      role="presentation"
      aria-label={label || undefined}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  );
}

export default SvgIcon;
