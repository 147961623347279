import { withChildren } from '@builder.io/react';
import styled from 'styled-components';
import SectionWrapper from '../base/SectionWrapper';
import { BaseSectionProps, SectionVariant } from '../types';
import Container from 'components/Container';

interface Props extends BaseSectionProps {
  variant?: SectionVariant;
  children: React.ReactNode;
  maxInnerWidth?: number;
}

const StyledContainer = styled(Container)<{
  $maxInnerWidth?: number;
}>`
  ${({ $maxInnerWidth }) =>
    $maxInnerWidth &&
    `
    max-width: ${$maxInnerWidth}px;
  `}
`;

function SectionContainer({
  children,
  variant,
  maxInnerWidth = 0,
  attributes,
}: Props) {
  return (
    <SectionWrapper variant={variant} attributes={attributes}>
      <StyledContainer $maxInnerWidth={maxInnerWidth}>
        {children}
      </StyledContainer>
    </SectionWrapper>
  );
}

export default withChildren(SectionContainer);
