import { DefaultTheme } from 'styled-components';
import {
  ComponentVariant,
  SectionVariant,
} from '../components/BuilderComponents/types';

type ThemeKeyAccessor = 'color' | 'background' | 'accent';

// raw function to generate a css access string
const cssVarGenerator = (variant: SectionVariant, key: ThemeKeyAccessor) => {
  return `var(--color-${variant}-${key})`;
};

/**
 * Function to get the best matching color for a component by several factors
 */
export const getBestMatchingColor =
  (key: ThemeKeyAccessor) =>
  ({
    theme,
    $variant,
  }: {
    theme: DefaultTheme;
    $variant?: ComponentVariant;
  }) => {
    // https://forum.builder.io/t/blank-option-in-text-enum-in-model-definition/211
    if ($variant && $variant !== 'inherit') {
      return cssVarGenerator($variant, key);
    }
    if (theme.variant) {
      return cssVarGenerator(theme.variant, key);
    }
    return cssVarGenerator('primary', key);
  };
