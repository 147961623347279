import { styled } from 'styled-components';
import { HTMLAttributes } from 'react';
import {
  CallToAction as BaseCallToAction,
  CallToActionProps,
} from 'styleguide/src/components/Blog';
import Typography from 'styleguide/src/components/Typography';
import useParseHTML from 'styleguide/src/hooks/useParseHTML';

const StyledText = styled(Typography)`
  margin: 0 0 24px 0;
`;

type Props = CallToActionProps & {
  attributes?: Omit<HTMLAttributes<HTMLElement>, 'color'>;
  className?: string;
  text?: string;
};

function CallToAction(props: Props) {
  const { headerText, text, button, className, attributes } = props;
  const { parseContent } = useParseHTML();

  if (!headerText) {
    return null;
  }

  return (
    <BaseCallToAction
      {...attributes}
      className={`${className || ''} ${attributes?.className || ''}`}
      headerText={headerText}
      button={button}
    >
      {text && (
        <StyledText tagName="div" variant="bodyStandard" color="black">
          {parseContent(text)}
        </StyledText>
      )}
    </BaseCallToAction>
  );
}

export default CallToAction;
