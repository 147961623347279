import CompactComponent, {
  CompactProps,
} from 'styleguide/src/components/website/Compact';
import { withChildren } from '@builder.io/react';
import { getColorFromTheme } from '../../../utils/helper';
import { useTheme } from 'styled-components';

function Compact(props: CompactProps) {
  const { containerBackground, backgroundColor, ...compactProps } = props;
  const theme = useTheme();

  return (
    <CompactComponent
      containerBackground={
        containerBackground && getColorFromTheme(theme, containerBackground)
      }
      backgroundColor={
        backgroundColor && getColorFromTheme(theme, backgroundColor)
      }
      {...compactProps}
    />
  );
}

export default withChildren(Compact);
