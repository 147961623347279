import { CSSProperties, PropsWithChildren } from 'react';
import {
  CompactCommonProps,
  CompactContainer,
  CompactContentContainer,
  CompactWrapper,
  StyledCompact,
} from './Compact.styles';
import { useTheme } from 'styled-components';

export type CompactProps = PropsWithChildren<
  CompactCommonProps & {
    containerBackground?: CSSProperties['background'];
    backgroundColor?: CSSProperties['backgroundColor'];
    className?: string;
  }
>;

export default function Compact(props: CompactProps) {
  const {
    containerBackground,
    backgroundColor,
    children,
    variant,
    alignment = 'center',
    className,
  } = props;

  const theme = useTheme();

  return (
    <CompactWrapper
      className={className}
      data-testid={'compact-container'}
      $background={containerBackground || theme.colors.gray50}
    >
      <CompactContainer $variant={variant}>
        <StyledCompact
          $variant={variant}
          $backgroundColor={backgroundColor || theme.colors.white}
        >
          <CompactContentContainer $alignment={alignment}>
            {children}
          </CompactContentContainer>
        </StyledCompact>
      </CompactContainer>
    </CompactWrapper>
  );
}
