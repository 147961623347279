import Typography, {
  TypographyProps,
} from 'styleguide/src/components/Typography';
import useParseHTML from 'styleguide/src/hooks/useParseHTML';
import styled from 'styled-components';
import { HTMLAttributes } from 'react';

type Props = Omit<TypographyProps, 'fontSize'> & {
  text?: string;
  fontSize?: number;
  attributes?: HTMLAttributes<HTMLElement>;
};

const StyledText = styled(Typography).withConfig({
  // builder.io passes us attributes for this element, but styled-components was stripping
  shouldForwardProp: () => true,
})`
  white-space: pre-wrap;
`;

export default function Text({
  text,
  tagName,
  variant,
  className,
  attributes,
  role,
  color,
}: Props) {
  const { parseContent } = useParseHTML({
    parseComponents: {
      p: ({ children }) => (
        <>
          {children}
          {`\n`}
        </>
      ),
    },
  });

  if (!text) return null;

  // remove anything that could potentially cause typescript issues
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { color: color1, ...cleanedAttributes } = attributes || {};

  return (
    <StyledText
      {...cleanedAttributes}
      className={`${className || ''} ${attributes?.className || ''}`}
      tagName={tagName}
      variant={variant}
      role={role}
      color={color}
    >
      {parseContent(text)}
    </StyledText>
  );
}
