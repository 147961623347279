import styled from 'styled-components';
import BaseSectionWrapper from '../base/SectionWrapper';
import { BaseSectionProps } from '../types';
import { getBestMatchingColor } from '../../../utils/styleUtils';
import Container from 'components/Container';

const SectionWrapper = styled(BaseSectionWrapper)`
  background: none;
`;
const HR = styled.hr`
  border: 0;
  border-top: 1px solid ${getBestMatchingColor('background')};
  margin: 0;
`;

function Divider({ variant }: BaseSectionProps) {
  return (
    <SectionWrapper variant={variant}>
      <Container>
        <HR />
      </Container>
    </SectionWrapper>
  );
}

export default Divider;
