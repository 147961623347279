import { styled } from 'styled-components';
import Image from 'next/image';
import { HTMLAttributes } from 'react';
import {
  MediaContainer,
  MediaContainerFit,
  MediaContainerProps,
} from 'styleguide/src/components/Blog';
import Container from 'styleguide/src/components/Blog/Container/Container';

const StyledImage = styled(Image)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  attributes?: Omit<HTMLAttributes<HTMLElement>, 'color'>;
  image: string;
  alt: string;
  imageFit: MediaContainerFit;
} & MediaContainerProps;

function BlogImage(props: Props) {
  const { image, alt, size, outline, imageFit, attributes } = props;

  return (
    <Container {...attributes}>
      <MediaContainer size={size} outline={outline} fit={imageFit}>
        <StyledImage src={image} alt={alt} layout="fill" />
      </MediaContainer>
    </Container>
  );
}

export default BlogImage;
