import { HTMLAttributes, useMemo } from 'react';
import {
  MediaContainer,
  MediaContainerFit,
  MediaContainerProps,
} from 'styleguide/src/components/Blog';
import Container from 'styleguide/src/components/Blog/Container/Container';

type Props = {
  attributes?: Omit<HTMLAttributes<HTMLElement>, 'color'>;
  video: string;
  videoFit: MediaContainerFit;
  controls: boolean;
} & MediaContainerProps;

function BlogVideo(props: Props) {
  const { video, size, outline, videoFit, controls, attributes } = props;

  const videoProps = useMemo(() => {
    if (!controls) {
      return {
        controls: false,
        autoPlay: true,
        muted: true,
      };
    }

    return {
      controls: true,
    };
  }, [controls]);

  return (
    <Container {...attributes}>
      <MediaContainer size={size} outline={outline} fit={videoFit}>
        <video {...videoProps}>
          <source src={video} type="video/mp4" />
          <source src={video} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </MediaContainer>
    </Container>
  );
}

export default BlogVideo;
