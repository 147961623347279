import React from 'react';
import AccordionComponent, {
  AccordionProps,
} from 'styleguide/src/components/Accordion';
import styled from 'styled-components';

const RichContent = styled.div`
  p {
    padding: 0;
    margin: 0;
  }
`;

type Props = AccordionProps & {
  content: string;
};

function Accordion(props: Props) {
  const { content: _content, ...restProps } = props;

  const content = (
    <div style={{ paddingRight: '100px' }}>
      <RichContent dangerouslySetInnerHTML={{ __html: _content }} />
    </div>
  );

  return <AccordionComponent {...restProps} content={content} />;
}

export default Accordion;
