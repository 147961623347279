import { Builder } from '@builder.io/react';
import theme from 'styleguide/src/themes/theme';
import { sideBySideVariants } from 'styleguide/src/components/website/SideBySide';
import { pageModel } from 'types/pageModels';
import Accordion from './Accordion';
import Divider from './Divider';
import SectionContainer from './SectionContainer';
import SideBySide from './SideBySide';
import Compact from './Compact';

const BaseSectionInputProps = [
  {
    name: 'variant',
    type: 'string',
    defaultValue: 'primary',
    enum: ['primary', 'secondary'],
  },
];

const BaseSectionBackgroundProps = [
  {
    name: 'containerBackground',
    type: 'string',
    required: false,
    enum: Object.keys(theme.colors),
    defaultValue: 'white',
  },
  {
    name: 'backgroundColor',
    type: 'string',
    required: false,
    enum: Object.keys(theme.colors),
    defaultValue: 'gray50',
  },
];

Builder.registerComponent(Divider, {
  models: [pageModel],
  name: 'Divider',
  inputs: [...BaseSectionInputProps],
});

Builder.registerComponent(SideBySide, {
  models: [pageModel],
  name: 'SideBySide',
  inputs: [
    ...BaseSectionBackgroundProps,
    {
      name: 'variant',
      type: 'string',
      defaultValue: 'left-top',
      enum: Array.from(sideBySideVariants),
      required: false,
    },
    {
      name: 'maxHeight',
      type: 'number',
      required: false,
    },
  ],
});

Builder.registerComponent(Compact, {
  models: [pageModel],
  name: 'Compact',
  inputs: [
    ...BaseSectionBackgroundProps,
    {
      name: 'variant',
      type: 'string',
      defaultValue: 'full',
      enum: ['full', 'inset'],
      required: false,
    },
    {
      name: 'alignment',
      type: 'string',
      defaultValue: 'center',
      enum: ['center', 'left'],
      required: false,
    },
  ],
});

Builder.registerComponent(SectionContainer, {
  models: [pageModel],
  name: 'Section Container',
  noWrap: true,
  inputs: [
    {
      name: 'maxInnerWidth',
      type: 'number',
      defaultValue: 0,
    },
    ...BaseSectionInputProps,
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: { text: 'Add your sub-components here' },
      },
    },
  ],
});

Builder.registerComponent(Accordion, {
  models: [pageModel],
  name: 'Accordion',
  noWrap: true,
  inputs: [
    { name: 'header', type: 'longText', defaultValue: 'Accordion Header...' },
    {
      name: 'content',
      type: 'richText',
      defaultValue: 'Accordion Content...',
    },
  ],
});
