import styled, { css } from 'styled-components';
import { default as NextLink } from 'next/link';

export interface LinkStyledProps {
  disabled?: boolean;
}

const baseLinkStyles = css<LinkStyledProps>`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily.main};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
`;

export const LinkStyled = styled.a<LinkStyledProps>`
  ${baseLinkStyles}
`;

export const ButtonLinkStyled = styled.a<LinkStyledProps>`
  background: none;
  border: none;
  padding: 0;
  ${baseLinkStyles}
`;

export const NextLinkStyled = styled(NextLink)<LinkStyledProps>`
  ${baseLinkStyles}
`;
