import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

type CompactVariants = 'full' | 'inset';

export type CompactCommonProps = {
  variant?: CompactVariants;
  alignment?: 'left' | 'center';
};

type StyledCompactComponentProps = {
  $variant?: CompactVariants;
  $alignment?: 'left' | 'center';
};

export const CompactWrapper = styled.div<{
  $background: CSSProperties['background'];
}>`
  background: ${({ $background }) => $background};
`;

const CompactContainerInset = css`
  box-sizing: border-box;
  padding: 50px 20px;
  @media ${({ theme }) => theme.breakpoints.large} {
    padding: 50px;
  }
`;

const CompactContainerFull = css`
  height: 100%;
`;

export const CompactContainer = styled.div<StyledCompactComponentProps>`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $variant }) =>
    $variant === 'inset' ? CompactContainerInset : CompactContainerFull};
`;

const StyledCompactInset = css`
  border-radius: 20px;
  @media ${({ theme }) => theme.breakpoints.large} {
    border-radius: 60px;
  }
`;

const StyledCompactFull = css`
  height: 100%;
`;

export const StyledCompact = styled.div<
  StyledCompactComponentProps & { $backgroundColor: string }
>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding: 40px 20px;
  ${({ $variant }) =>
    $variant === 'inset' ? StyledCompactInset : StyledCompactFull}
  @media ${({ theme }) => theme.breakpoints.large} {
    padding: 54px 80px 60px 80px;
  }
`;

export const CompactContentContainer = styled.div<StyledCompactComponentProps>`
  max-width: 1114px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.large} {
    align-items: ${({ $alignment }) =>
      $alignment === 'center' ? $alignment : 'flex-start'};
  }
`;
