import { HTMLAttributes } from 'react';
import { withChildren } from '@builder.io/react';
import Container from 'styleguide/src/components/Blog/Container/Container';
import {
  MediaContainer as BaseMediaContainer,
  MediaContainerProps,
} from 'styleguide/src/components/Blog';

type Props = MediaContainerProps & {
  attributes?: Omit<HTMLAttributes<HTMLElement>, 'color'>;
  children: React.ReactNode;
};

function MediaContainer({ children, attributes, size }: Props) {
  return (
    <Container {...attributes}>
      <BaseMediaContainer size={size}>{children}</BaseMediaContainer>
    </Container>
  );
}

export default withChildren(MediaContainer);
