import { BackButtonContainer, Heading, Wrapper } from './NotFound.styles';
import Button from '../../Button';
import Link from '../../Link/Link';

export default function NotFound() {
  return (
    <Wrapper>
      <Heading variant={'heading1'} tagName={'h1'} style={{ marginBottom: 10 }}>
        404
      </Heading>
      <Heading variant={'heading6'} tagName={'p'}>
        Oops! Looks like you followed a bad link.
        <br />
        If you think this is a problem with us,{' '}
        <Link external href="mailto:vendorsupport@wreno.io">
          please tell us
        </Link>
        .
      </Heading>

      <BackButtonContainer>
        <Button href={'/'}>Back home</Button>
      </BackButtonContainer>
    </Wrapper>
  );
}
