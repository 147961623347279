import styled from 'styled-components';

export default styled.div`
  // always add 20px padding both sides, or normal max width
  max-width: min(
    calc(100vw - 2rem),
    ${({ theme }) => theme.variables.maxWidth}px
  );
  margin: 0 auto;
  width: 100%;
  @media ${({ theme }) => theme.breakpoints.medium} {
    max-width: min(
      calc(100vw - 48px),
      ${({ theme }) => theme.variables.maxWidth}px
    );
  }
`;
